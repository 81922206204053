import React, { useState, useEffect } from 'react';

import './assets/css/App.css';

import logo from './assets/images/logo.png';

import Main from './Main';

function App() {

    const [windowDimenion, detectHW] = useState({
        winWidth: window.innerWidth,
        winHeight: window.innerHeight,
    })
    
    const detectSize = () => {
        detectHW({
          winWidth: window.innerWidth,
          winHeight: window.innerHeight,
        })
    }
    
    useEffect(() => {
        window.addEventListener('resize', detectSize)
        return () => {
          window.removeEventListener('resize', detectSize)
        }
    }, [windowDimenion]);

  return (
    <div className="App">
        <div className='App-Head'>
            <div style={{marginTop:"50px", marginLeft:windowDimenion.winWidth < 800 ? "5vw" : "25vw", display:"flex", flexDirection:"column", justifyContent:"flex-end", alignItems:"flex-start"}}>
                <img src={logo} style={{width:"120px"}} alt=""/>
            </div>
        </div>
        <div className='App-Body'>
            <Main windowDimenion={windowDimenion}/>
        </div>
        <div className='App-Bottom'>
            <text style={{fontFamily:"Pretendard", fontSize:"1rem", textAlign:"center", color: "#fff"}}>
                Copyrightⓒ DKSOFT. All Rights Reserved.
            </text>
        </div>
    </div>
  );
}

export default App;
